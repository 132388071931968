import React from 'react'
import Link from 'gatsby-link'
import LayoutBs from '../../components/LayoutBs'
import "animate.css/animate.min.css";
import thankyou from "../../images/thankyou.png";
import { thankyou_text} from '../../components/strings'
import AOS from 'aos';

class ThankyouPageBs extends React.Component{
    componentDidMount(){
        AOS.init({
          duration : 500
        })
      }
    render(){
        return(
  <LayoutBs>
    <div data-aos='fade-zoom-in'>
      
        <section id="p_thankyou" className="hero is-fullheight pages">
    <div className="hero-head"></div>
        <div className="hero-body is-padding-0">
            <div className="overlay_image"></div>
            <div className="overlay_color"></div>
           <div className="image-box"> <img src={thankyou} /></div>
           <div className="title-box"><h2 className="title">{thankyou_text}<br /> <br /><Link to="/bs">Početna stranica</Link></h2></div>
            <div className="container"> 
             
               
            </div>
        </div>
    <div className="hero-foot">
   
    </div>
</section>
    </div>
</LayoutBs>
)
        }
    }
export default ThankyouPageBs
